body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.next-box {
  border-radius: 25px;
  background: #F6F8FC;
  min-height: 128px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 24px;
  padding: 24px 40px;
}

.title-one {
  /* Let’s start choosing your base! */
  height: 29px;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  /* Light/Text/Primary */
  color: rgba(0, 0, 0, 0.87);
}


.title-two {
  /* Typography/Body 1 */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.15px;
  color: #707070;
}

.next-button {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 150%;
  margin-bottom: 5px;
}

.colour-box {
  min-width: 120px;
  min-height: 180px;
  background: #DEEFB7;
  border-radius: 24px;
  margin: 5px;
}

.colour-box-download {
  min-width: 150px;
  min-height: 150px;
  background: #DEEFB7;
  border-radius: 24px;
  margin: 5px;
  max-width: 50% !important;
}


.random-button {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.colour-text {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  height: 60%;
}

.margin-auto {
  margin: auto;
  display: flex;
}

.live-demo {
  height: 895px;
  width: 1117px;

}

.live-demo-content {
  height: 100%;
  width: 100%;
  padding: 120px 0px 50px 50px;
}

.live-demo-header{
  position: absolute;
  left: 25.46%;
  right: 19.77%;
  top: 36.8%;
  bottom: -0.98%;

  background: #1F2042;
  }


.action-buttons {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: -35px;
  position: relative;
}

.popup {
  position: absolute;
  right: 6px;
  padding: 10px;
  background-color: white;
  z-index: 9999;
}

.close-btn {
  position: absolute;
  top: 5px;
  right: 5px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.svg-live {
  width: 100%;
  height: auto;
}
.debug {
 /*border: 1px solid black;*/
}

.navbar {
  display:flex;
  align-items:center;
}

.is-mobile-only{
  display: none;
}

.product-hunt {
  width: 300px !important;
  height: 300px !important;
}
@media only screen and (max-width: 600px) {
  .is-mobile-only{
    display: block;
  }
}
